
import React from "react";



import Header from "./header";
// import Background from "./background";


import styles from "./minimalLayout.module.css";

const miniLayout = ({ children }) => {


  return (
    <div className={styles.wrapper}>

      {children}
    </div>
  )
}


export default miniLayout
