import React from "react"
// import { Link } from "gatsby"

import Layout from "../../components/layout"
// import Week from "../components/week"
// import Schedule from "../components/schedule"
import MiniLayout from "../../components/minimalLayout"


const There = ({data}) => {

  return (
    <Layout>

      <MiniLayout>
        <h2>(t)here (project C)</h2>
        <p>“See you on the Internet”. In the physical world, I am either here or there and so are you. If I am here, but you are there, I can only imagine you. Here and there are never the same. Space separates them and often more; We might never meet. Who are you?</p>
        <p>Imagine the website* as a space of encounter, a plaza where here and there converge. The browser then is a window onto this plaza. What can I see behind the glass? And if I climb through it, how am I to interact with fellow humans that found their way (t)here, too? This plaza is yours to construct, and the interactions on it are yours to orchestrate.</p>

        <p>*this may be a Chrome Extension, too.</p>
      </MiniLayout>
    </Layout>
  )
}



export default There